import { v4 as uuidv4 } from 'uuid';

export class UsersEntity {
    constructor(id, name, email, password, passwordConfirmation, active, resetPassword, lastReadNotification, modifiedAt, createdAt,
        createdBy, updatedBy, roles) {

        this.id = id;
        this.name = name;
        this.email = email;
        this.active = active;
        this.password = password;
        this.passwordConfirmation = passwordConfirmation;
        this.resetPassword = resetPassword;
        this.lastReadNotification = lastReadNotification;
        this.modifiedAt = modifiedAt;
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.updatedBy = updatedBy;
        this.roles = roles;

        this.guid = uuidv4();
    }

    static create() {
        return new UsersEntity(0, '', '', '', '', '', '',
            '', '', '', '', '', []);
    }
}