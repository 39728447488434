<template>
    <b-overlay :show="isLoading">
        <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
                <b-button @click="showModalUser('')" variant="primary">Add user</b-button>
                <b-modal id="users-modal" ref="modal" :title="usersEntity.id > 0 ? 'Edit user' :'Add user'" size="xl" centered no-close-on-backdrop no-close-on-esc>
                    <ValidationObserver v-slot="{ passes }">
                        <b-form @submit.prevent="passes(saveUsers)" id="users-form">
                            <b-row>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Name" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="usersEntity.name" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required|email" v-slot="{ errors }">
                                        <b-form-group label="Email" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="usersEntity.email" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <b-form-group label="Active">
                                        <b-button size="sm" variant="outline-primary" @click="usersEntity.active = !usersEntity.active" autocomplete="off">
                                            <font-awesome-icon :icon="usersEntity.active ? 'toggle-on' : 'toggle-off'"></font-awesome-icon>
                                        </b-button>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" sm="12" v-if="usersEntity.id">
                                    <b-form-group label="Update password">
                                        <b-button size="sm" variant="outline-primary" @click="toggleUpdatePassword">
                                            <font-awesome-icon :icon="updatedPassword ? 'toggle-on' : 'toggle-off'"></font-awesome-icon>
                                        </b-button>
                                    </b-form-group>
                                </b-col>
                                <b-col :md="usersEntity.id ? 4 : 6" sm="12">
                                    <ValidationProvider :rules="passwordRules" v-slot="{ errors }" vid="password">
                                        <b-form-group label="Password" :invalid-feedback="errors[0]">
                                            <b-form-input name="password" type="password" v-model="usersEntity.password" :state="errors[0] ? false : null"
                                                          autocomplete="off" :disabled="usersEntity.id ? !updatedPassword : false"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col :md="usersEntity.id ? 4 : 6" sm="12">
                                    <ValidationProvider :rules="passwordConfirmationRules" v-slot="{ errors }">
                                        <b-form-group label="Confirm password" :invalid-feedback="errors[0]">
                                            <b-form-input  name="password_confirmation  " type="password" v-model="usersEntity.passwordConfirmation" :state="errors[0] ? false : null"
                                                           autocomplete="off" :disabled="usersEntity.id ? !updatedPassword : false"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
<!--                                <b-col md="12" sm="12">-->
<!--                                    <ValidationProvider rules="required" v-slot="{ errors }">-->
<!--                                        <b-form-group label="Role description" :invalid-feedback="errors[0]" :clearable="false" :state="errors[0] ? false : null">-->
<!--                                            <v-select label="text" :options="role" @search:focus="getUserRoles" @search="getUserRoles"-->
<!--                                                      :reduce="roleId => roleId.value" v-model="usersEntity.roleId"-->
<!--                                                      placeholder="Select role">-->
<!--                                                <template #no-options>No results</template>-->
<!--                                            </v-select>-->
<!--                                        </b-form-group>-->
<!--                                    </ValidationProvider>-->
<!--                                </b-col>-->
                            </b-row>
                            <b-row class="mt-4">
                                <b-col sm="12" md="12" lg="12">
                                <h3 class="text-center">PERMISSIONS</h3>
                                    <b-table-simple responsive bordered hover>
                                        <b-thead>
                                            <b-tr>
                                                <b-th></b-th>
                                                <b-th>Module</b-th>
                                                <b-th v-if="permissionTypes.length > 1" class="text-center"><font-awesome-icon icon="th"></font-awesome-icon> All</b-th>
                                                <b-th v-for="(type, index) of permissionTypes" :key="index" class="text-center">
                                                    <font-awesome-icon :icon="type.icon"></font-awesome-icon> {{ type.title }}
                                                </b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr class="text-center">
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td style="background-color: #f5f5f6">
                                                    <b-form-checkbox v-model="allPermissionsSelected" @change="changeAllPermissions">
                                                    </b-form-checkbox>
                                                </b-td>
                                                <b-td v-for="(type, index) of permissionTypes" :key="index" style="background-color: #f5f5f6">
                                                    <b-form-checkbox v-model="permissionCols" :value="type.id" @change="changePermissionCols(type.id)">
                                                    </b-form-checkbox>
                                                </b-td>
                                            </b-tr>
                                            <template v-for="module in modules">
                                                <template v-for="(submodule, idx) of module.submodules">
                                                    <b-tr :key="module.title + '-' + idx">
                                                        <b-td :rowspan="module.submodules.length" v-if="idx === 0"><strong>{{ module.title }}</strong></b-td>
                                                        <b-td v-if="submodule.title"><strong>{{ submodule.title }}</strong></b-td>
                                                        <b-td v-else><span style="font-size: 10.7px;">&nbsp;- {{ submodule.subtitle }}</span></b-td>
                                                        <b-td style="background-color: #f5f5f6" class="text-center">
                                                            <b-form-checkbox v-model="permissionRows" :value="submodule.id" @change="changePermissionRows(submodule.id)">
                                                            </b-form-checkbox>
                                                        </b-td>
                                                        <b-td v-for="(type, index) of permissionTypes" :key="index">
                                                            <b-form-checkbox v-model="roles" :value="'ROLE_' + submodule.id + '_' + type.id" v-if="submodule.permissions.includes(type.id)">
                                                            </b-form-checkbox>
                                                        </b-td>
                                                    </b-tr>
                                                </template>
                                            </template>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button @click="$bvModal.hide('users-modal')" class="mr-2" variant="outline-secondary"> Cancel</b-button>
                            <b-button type="submit" form="users-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
                        </div>
                    </template>
                </b-modal>
            </b-col>
        </b-row>

        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Show" append="records">
                    <b-form-select v-model="users.itemsPerPage" id="per-page-select" size="sm" :options="users.pageOptions" style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
        </b-row>

        <b-table :items="loadData" :fields="users.fields" :filter="users.filter" :current-page="users.currentPage"
                 :per-page="users.itemsPerPage" empty-text="No records" striped bordered hover small show-empty
                 :responsive="true" :sort-by="users.sortBy" :sort-desc="users.sortDesc" ref="usersTable"
                 class="text-left mb-0" @context-changed="tableContextChanged">

            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in users.fields" :key="field.key" :class="users.filters[index] && users.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="users.filters[index]">
                            <b-col v-if="users.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="users.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>
            <template #cell(active)="row">
                <p v-if="row.item.active == true">Active</p>
                <p v-else>Inactive</p>
            </template>
            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" @click="showModalUser(row.item)" v-b-tooltip.hover title="Edit" variant="outline-primary">
                        <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                    </b-button>
<!--                    <b-button size="sm" v-b-tooltip.hover :title="!row.item.active ? 'Active' : 'Inactive'" variant="outline-primary" @click="changeStatus(row.item)">-->
<!--                        <font-awesome-icon :icon="row.item.active ? 'toggle-on' : 'toggle-off'"></font-awesome-icon>-->
<!--                    </b-button>-->
<!--                    <b-button size="sm" @click="deleteUser(row.item.id)" v-b-tooltip.hover title="Delete" variant="outline-primary">
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </b-button>-->
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Show {{ start }} to {{ end }} of {{ users.totalRows }} record{{users.totalRows !== 1 ? 's' : ''}}
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="users.currentPage" :total-rows="users.totalRows" :per-page="users.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
    </b-overlay>
</template>
<script>
import { extend } from 'vee-validate';
import { UsersEntity } from '../../models/users';
import { saveUserUrl, userListUrl, userDeleteUrl, userGetRoles, userChangeStatus }  from '@routes';
import tableStateMixin from '../../mixins/tableState';
import { required, email, confirmed } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: 'Field required'
});

extend('email', {
    ...email,
    message: 'Invalid email format'
});

extend('confirmed', {
    ...confirmed,
    message: 'Password must be the same'
});
export default {
    mixins: [tableStateMixin],
    data() {
        return {
            usersEntity: UsersEntity.create(),
            toastTitle: 'Users',
            isLoading: false,
            users: {
                fields: [
                    { key: 'name', label: 'Name', sortable: true },
                    { key: 'email', label: 'Email', sortable: true },
                    // { key: 'roleTitle', label: 'Role description', sortable: true, class: 'text-center' },
                    { key: 'active', label: 'Active', sortable: true, class: 'text-center' },
                    /*{ key: 'createdBy', label: 'created by', sortable: true, class: 'text-center' },
                    { key: 'updatedBy', label: 'updated by', sortable: true, class: 'text-center' },*/
                    { key: 'actions', label: 'Actions', class: 'text-center' }
                ],
                filters: [
                    { column: 'name', type: 'STRING', value: '', format: '' },
                    { column: 'email', type: 'STRING', value: '', format: '' }
                ],
                filter: '',
                pageOptions: [10, 25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'name   ',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Users',
                path: []
            },
            role: [],
            updatedPassword: false,
            permissionTypes: [
                { id: 'L', title: 'List', icon: 'list' },
                { id: 'C', title: 'Write', icon: 'edit' }
            ],
            permissionRows: [],
            permissionCols: [],
            permissionColsCount: [],
            permissionRowsPrev: [],
            permissionColsPrev: [],
            allPermissionsSelected: false,
            roles: [],
            modules: [
                // {
                //     title: 'GENERAL',
                //     submodules: [
                //         { id: 'DASH', title: 'DASHBOARD', permissions: ['L']}
                //     ]
                // },
                {
                    title: 'PRODUCTION',
                    submodules: [
                        { id: 'PRLI', title: 'PRODUCTION LINES', permissions: ['L', 'C']},
                        { id: 'PRLI_PLAN', subtitle: 'PLANNING', permissions: ['L', 'C']},
                        { id: 'PRLI_SOWI', subtitle: 'SOWING', permissions: ['L', 'C']},
                        { id: 'PRLI_GERM', subtitle: 'GERMINATION', permissions: ['L', 'C']},
                        { id: 'PRLI_TIFL', subtitle: 'TIDAL FLOOR', permissions: ['L', 'C']},
                        { id: 'PRLI_COTR', subtitle: 'COMPARTMENT TRANSPLANT', permissions: ['L', 'C']},
                        { id: 'PRLI_POHA', subtitle: 'POLLEN HARVEST', permissions: ['L', 'C']},
                        { id: 'PRLI_EMPO', subtitle: 'EMASCULATION POLLINATION', permissions: ['L', 'C']},
                        { id: 'PRLI_HARV', subtitle: 'HARVEST', permissions: ['L', 'C']},
                        { id: 'PRLI_EXTR', subtitle: 'EXTRACTION', permissions: ['L', 'C']}/*,
                        { id: 'BULO', title: 'BULK LOAD', permissions: ['L', 'C']}*/
                    ]
                },
                {
                    title: 'COMPARTMENTS',
                    submodules: [
                        { id: 'COMP', title: 'COMPARTMENTS', permissions: ['L', 'C']},
                        { id: 'COMP_PRAS', subtitle: 'PRODUCTION LINES ASSIGNATION', permissions: ['L', 'C']}
                    ]
                },
                {
                    title: 'CONFIGURATION',
                    submodules: [
                        { id: 'USER', title: 'USERS', permissions: ['L', 'C']}
                    ]
                },
                {
                    title: 'CATALOGUES',
                    submodules: [
                        { id: 'CROP', title: 'CROPS', permissions: ['L', 'C']},
                        { id: 'PARE', title: 'PARENTS', permissions: ['L', 'C']},
                        { id: 'VARI', title: 'VARIETIES', permissions: ['L', 'C']}
                    ]
                }
            ]
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.users, this.tableState);
    },
    methods: {
        loadData(users) {
            this.isLoading = true;

            if (users.sortBy !== '') {
                let data = {
                    filters: this.users.filters.filter(filter => filter.value.trim() !== ''),
                    ...users
                };
                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(userListUrl(), data).then(response => {
                    if (response.data.code === 'OK') {
                        this.users.totalRows = response.data.totalRows;
                        return response.data.users;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$bvToast.toast(error.response.data.message, {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else if (error.request) {
                        this.$bvToast.toast('An error occurred while executing the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else {
                        this.$bvToast.toast('An error occurred while configuring the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        saveUsers() {
            this.isLoading = true;
            this.usersEntity.roles = this.roles;
            this.axios.post(saveUserUrl(this.usersEntity.id), this.usersEntity).then(response => {
                if (response.data.code === 'OK') {
                    this.$bvModal.hide('users-modal');
                    this.roles = [];
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });
                    this.$refs.usersTable.refresh();
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        deleteUser(userId) {
            this.isLoading = true;

            this.$swal({
                title: 'Are you sure to delete User?',
                icon: 'warning',
                iconColor: '#bf0d0d',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(userDeleteUrl(), {
                        id: userId
                    }).then(response => {
                        if (response.data.code === 'OK') {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            this.$refs.usersTable.refresh();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        showModalUser(row) {
            if (row.id > 0) {
                this.usersEntity = JSON.parse(JSON.stringify(row));
                this.roles = this.usersEntity.roles;
                //console.log(this.roles);
                this.updatedPassword = false;
                //this.getUserRoles();
            } else {
                this.usersEntity = UsersEntity.create();
                this.roles = [];
                this.usersEntity.active = true;
            }

            this.usersEntity.password = '';
            this.$bvModal.show('users-modal');
        },
        getUserRoles() {
            this.axios.get(userGetRoles()).then(response => {
                this.role = response.data.data.roles;
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            });
        },
        toggleUpdatePassword() {
            this.updatedPassword = !this.updatedPassword;
            this.usersEntity.updatedPassword = this.updatedPassword;
        },
        changeStatus(user) {
            this.isLoading = true;

            this.$swal({
                title: 'Are you sure to change the status?',
                html: `The user status will change to <strong>${!user.active ? 'Active' : 'Inactive'} <strong>`,
                icon: 'warning',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#97bf0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.put(userChangeStatus(), {
                        id: user.id,
                        active: !user.active
                    }).then(response => {
                        if (response.data.status === 200) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            this.$refs.usersTable.refresh();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        tableContextChanged(context) {
            this.users.sortBy = context.sortBy;
            this.users.sortDesc = context.sortDesc;
            this.saveTableState(this.$route.name, this.users);
        },
        changePermissionCols(id) {
            let colIndex = this.permissionCols.findIndex(permission => permission === id);

            for (let module of this.modules) {
                for (let submodule of module.submodules) {

                    let col = this.roles.findIndex(role => role === 'ROLE_' + submodule.id + '_' + id);
                    if (colIndex >= 0) {
                        if (col < 0) {
                            this.roles.push('ROLE_' + submodule.id + '_' + id);
                        }
                    } else {
                        if (col >= 0) {
                            this.roles.splice(col, 1);
                        }
                    }
                }
            }
        },
        changePermissionRows(id) {
            let rowIndex = this.permissionRows.findIndex(permission => permission === id);

            for (let type of this.permissionTypes) {
                let row = this.roles.findIndex(role => role === 'ROLE_' + id + '_' + type.id);
                if (rowIndex >= 0) {
                    if (row < 0) {
                        this.roles.push('ROLE_' + id + '_' + type.id);
                    }
                } else {
                    if (row >= 0) {
                        this.roles.splice(row, 1);
                    }
                }
            }
        },
        changeAllPermissions() {
            if (!this.allPermissionsSelected) {
                this.permissionRows = [];
                this.permissionCols = [];
                this.roles = [];
            } else {
                this.permissionCols = this.permissionTypes.map(type => type.id);

                for (let type of this.permissionTypes) {
                    this.changePermissionCols(type.id);
                }
            }
        }
    },
    watch: {
        'users.filters': {
            handler() {
                this.$refs.usersTable.refresh();
                this.saveTableState(this.$route.name, this.users);
            },
            deep: true
        },
        roles() {
            let submodulesRoles = [];

            for (let col of this.permissionTypes) {
                let countCols = 0, totalColRoles = 0;

                for (let module of this.modules) {
                    for (let submodule of module.submodules) {
                        totalColRoles++;

                        if (!submodulesRoles.find(object => object.module === submodule.id)) {
                            submodulesRoles.push({
                                module: submodule.id,
                                count: 0
                            });
                        }

                        if (this.roles.find(role => role === 'ROLE_'+ submodule.id + '_' + col.id)) {
                            countCols++;
                            let  submoduleRol = submodulesRoles.find(object => object.module === submodule.id);
                            submoduleRol.count++;
                        }
                    }
                }

                let value = this.permissionCols.findIndex(permission => permission === col.id);

                if (countCols === totalColRoles) {
                    if (value < 0) {
                        this.permissionCols.push(col.id);
                    }
                } else if (value >= 0) {
                    this.permissionCols.splice(value, 1);
                }
            }

            for (let submodule of submodulesRoles) {
                let value = this.permissionRows.findIndex(permission => permission === submodule.module);

                if (submodule.count === this.permissionTypes.length) {
                    if (value < 0) {
                        this.permissionRows.push(submodule.module);
                    }
                } else if (value >= 0) {
                    this.permissionRows.splice(value, 1);
                }
            }

            this.allPermissionsSelected = this.permissionCols.length > 0 && this.permissionCols.length === this.permissionTypes.map(type => type.id).length;
        }
    },
    computed: {
        start() {
            return this.users.totalRows === 0 ? 0 : (this.users.currentPage - 1) * this.users.itemsPerPage + 1;
        },
        end() {
            let offset = (this.users.currentPage - 1) * this.users.itemsPerPage;

            return (this.users.totalRows - offset) < this.users.itemsPerPage ? this.users.totalRows : offset + this.users.itemsPerPage;
        },
        passwordRules() {
            return this.updatedPassword || this.usersEntity.id == 0 ? 'required' : '';
        },
        passwordConfirmationRules() {
            return this.updatedPassword || this.usersEntity.id == 0 ? 'required|confirmed:password' : '';
        }
    }
};
</script>